import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { map, take } from "rxjs/operators";

// Most of the data fetching is here
@Injectable()
export class SharedService {
  eventInfo: any = {};
  accountInfo: any = {};
  guests: Observable<any> = new Observable();
  accounts: Observable<any> = new Observable();
  sharedEncoder: string;
  sharedEncoderList: any = [];

  constructor(private afs: AngularFirestore, private sfAuth: AngularFireAuth) {}

  campPrograms() {
    return this.afs.collection<any>("camp_programs").valueChanges();
  }

  getUser(): Observable<any> {
    return this.afs
      .collection<any>("users", ref =>
        ref.where("uid", "==", this.sfAuth.auth.currentUser.uid)
      )
      .snapshotChanges()
      .pipe(
        take(1),
        map(actions =>
          actions.map(a => {
            console.log("user from cache: " + a.payload.doc.metadata.fromCache);
            return a.payload.doc.data();
          })
        )
      );
  }

  getEvents(camp: string, program: string) {
    return this.afs
      .collection<any>(`events`, ref =>
        ref
          .where("camp", "==", camp)
          .where("program", "==", program)
          .orderBy("arrival", "asc")
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            console.log(
              "events from cache: " + a.payload.doc.metadata.fromCache
            );
            return a.payload.doc.data();
          })
        )
      );
  }

  getSingleEvent(id: string) {
    return this.afs
      .collection<any>(`events`, ref => ref.where("sfId", "==", id))
      .snapshotChanges()
      .pipe(
        take(1),
        map(actions =>
          actions.map(a => {
            console.log(
              "single event from cache: " + a.payload.doc.metadata.fromCache
            );
            return a.payload.doc.data();
          })
        )
      );
  }

  getSingleEventFromOpp(id: string) {
    return this.afs
      .collection<any>(`opportunities`, ref => ref.where("sfId", "==", id))
      .snapshotChanges()
      .pipe(
        take(1),
        map(actions =>
          actions.map(a => {
            console.log(
              "single opp from cache: " + a.payload.doc.metadata.fromCache
            );
            return a.payload.doc.data();
          })
        )
      );
  }

  compare(a, b) {
    const first = a.name;
    const second = b.name;
    let comparison = 0;
    if (first > second) {
      comparison = 1;
    } else if (first < second) {
      comparison = -1;
    }
    return comparison;
  }
}
