import { PhoneNumberPipe } from "./pipes/phone-number.pipe";
import { CheckedInPipe } from "./pipes/checked-in.pipe";
import { Colors } from "./check-in-parent/shared-services/colors";
import { SharedService } from "./check-in-parent/shared-services/shared.service";
import { LoaderService } from "./loading/loader.service";
import { MoreInformationService } from "./check-in-parent/more-information/more-information.service";
import { CheckInService } from "./check-in-parent/check-in/check-in.service";
import { AppRouting } from "./app.routing";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';

import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";

import {
  AngularFirestoreModule,
  FirestoreSettingsToken
} from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { MyDatePickerModule } from "mydatepicker";
import { NgxPaginationModule } from "ngx-pagination";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { AccountComponent } from "./check-in-parent/account-search/account/account.component";
import { AccountSearchComponent } from "./check-in-parent/account-search/account-search.component";
import { EventsSearchComponent } from "./check-in-parent/events-search/events-search.component";
import { EventComponent } from "./check-in-parent/events-search/event/event.component";
import { CheckInComponent } from "./check-in-parent/check-in/check-in.component";
import { GuestComponent } from "./check-in-parent/check-in/guest/guest.component";
import { RemoveComponent } from "./check-in-parent/check-in/remove/remove.component";
import { LoadingComponent } from "./loading/loading.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { CreateFormComponent } from "./login/create-form/create-form.component";
import { CheckInParentComponent } from "./check-in-parent/check-in-parent.component";
import { MoreInformationComponent } from "./check-in-parent/more-information/more-information.component";
import { ArrivalDatePipe } from "./pipes/arrival-date.pipe";
import { SearchPipe } from "./pipes/search.pipe";
import { SortByPipe } from "./pipes/sort-by.pipe";
import { ProgramPipe } from "./pipes/program.pipe";
import { DebouncedInputComponent } from "./check-in-parent/debounced-input/debounced-input.component";
import { CheckboxesComponent } from "./check-in-parent/checkboxes/checkboxes.component";
import { CheckboxesFilterPipe } from "./pipes/checkboxes-filter.pipe";
import { RegStatusPipe } from "./pipes/reg-status.pipe";
import { CutTextPipe } from "./pipes/cut-text.pipe";

import { LoggedInGuard } from "./guards/logged-in.guard";
import { AccountsService } from "./check-in-parent/account-search/account.service";
import { EventSearchService } from "./check-in-parent/events-search/event-search.service";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { ApiHttpService } from "./check-in-parent/check-in/api-http-service.service";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccountComponent,
    AccountSearchComponent,
    EventsSearchComponent,
    EventComponent,
    CheckInComponent,
    GuestComponent,
    RemoveComponent,
    LoadingComponent,
    LoginFormComponent,
    CreateFormComponent,
    CheckInParentComponent,
    MoreInformationComponent,
    ArrivalDatePipe,
    SearchPipe,
    ProgramPipe,
    CheckedInPipe,
    PhoneNumberPipe,
    DebouncedInputComponent,
    SortByPipe,
    CheckboxesComponent,
    CheckboxesFilterPipe,
    RegStatusPipe,
    CutTextPipe,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRouting,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule.enablePersistence(),
    MyDatePickerModule,
    NgxPaginationModule,
    HttpClientModule
  ],
  providers: [
    MoreInformationService,
    CheckInService,
    LoaderService,
    SharedService,
    Colors,
    LoggedInGuard,
    AccountsService,
    EventSearchService,
    CheckInComponent,
    ApiHttpService,
    { provide: FirestoreSettingsToken, useValue: {} } //temporary fix
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
