import { CheckInService } from "./../check-in.service";
import { Colors } from "./../../shared-services/colors";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SharedService } from "../../shared-services/shared.service";
import { Location } from "@angular/common";
import { CheckInComponent } from "../check-in.component";
import { EventSearchService } from "../../events-search/event-search.service";

@Component({
  selector: "guest",
  templateUrl: "./guest.component.html",
  styleUrls: ["./guest.component.scss"]
})

// Presentational component for a Guest used by the check-in and remove pages
export class GuestComponent implements OnInit {
  @Input() info: any;
  @Output() infoOpening = new EventEmitter<string>();
  @Output() makeSingleKey = new EventEmitter<any>();
  @Output() singleCheckIn = new EventEmitter<any>();

  eventInfo: any;
  color: string;
  checkType: string;
  balanceComplete: boolean;

  constructor(
    public shared: SharedService,
    public colors: Colors,
    public checkService: CheckInService,
    public location: Location,
    public checkComp: CheckInComponent,
    public eventSearch: EventSearchService
  ) {}

  ngOnInit() {
    console.log('this.info: ',this.info);
    this.setupPage();
    if (location.pathname.includes("check-out")) {
      this.checkType = "Un-Check In";
    } else {
      this.checkType = "Check In";
    }

    this.balanceComplete =
      (this.info.regStyle === "Individual" && this.info.balance > 0) || true;
  }

  setupPage() {
    this.color = this.colors.programColors[
      this.shared.eventInfo.program.replace(" ", "")
    ];
  }

  toggleSelect() {
    this.info.selected = !this.info.selected;
  }

  showMoreInfo(event) {
    event.stopPropagation();
    this.infoOpening.emit(this.info.sfId);
  }

  testGuest(event) {
    // console.log('testGuest event: ', event);
    // console.log('this.info.sfId: ', this.info.sfId);
    event.stopPropagation();
    let bookingNum = 0;
    let selectedBooking = false;
    if(this.info.booking && this.info.booking.length > 1){
      for (let i = 0; i < this.info.booking.length; i++) {
        const b = this.info.booking[i];
        selectedBooking = confirm('This person has more than one booking, is this the booking for which you need a key? ' + b.Resource__r.Name + ' ' + b.CheckIn_Date__c + ' – ' + b.Checkout_Date__c);
        if(selectedBooking == true){
          bookingNum = i;
          break;
        }
      }
    } else if (this.info.booking && this.info.booking.length == 1){
      selectedBooking = true;
      bookingNum = 0;
    } else {
      console.log('ehh?');
    }

    if(!selectedBooking){
      alert('You must select a booking to make a key!');
      return;
    }

    console.log('bookingNum: ', bookingNum);
    let bookingName = this.info.booking[bookingNum].Resource__r.Name;
    let checkInDate = this.info.booking[bookingNum].CheckIn_Date__c;
    let checkoutDate = this.info.booking[bookingNum].Checkout_Date__c;
    let inviteeId = this.info.sfId;
    let bookingInfo = {inviteeId: inviteeId, name: bookingName, checkInDate: checkInDate, checkoutDate: checkoutDate};
    // this.infoOpening.emit(this.info.sfId);
    console.log({bookingInfo});
    this.makeSingleKey.emit(bookingInfo);
  }

  wbAssign(event){
    event.stopPropagation();
    if(this.checkComp.encoderList.includes('OFFLINE MODE')){
      alert('You must be online to assign a wristband to this contact');
      return;
    } else if(this.checkComp.chosenEncoder == ''){
      alert('You must select an encoder before assigning a wristband');
      return;
    } else {
      this.checkComp.readWristbandRequest(this.checkComp.chosenEncoder, this.info.sfId);
    }
  }

  checkIn(event) {
    event.stopPropagation();
    console.log('this.checkComp.autoWristband', this.checkComp.autoWristband);
    console.log('this.info.sfId', this.info.sfId);
    if(this.checkComp.autoWristband == true)
      this.checkComp.readWristbandRequest(this.checkComp.chosenEncoder, this.info.sfId);
    this.singleCheckIn.emit(this.info);
  }
}
