import { OnDestroy } from "@angular/core";
import { LoaderService } from "./../../loading/loader.service";
import { CheckedInPipe } from "./../../pipes/checked-in.pipe";
import { CheckInService } from "./check-in.service";
import { SharedService } from "./../shared-services/shared.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PaginationInstance } from "ngx-pagination";
import { MoreInformationService } from "./../more-information/more-information.service";
import { Component, OnInit, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { ApiHttpService } from "./api-http-service.service";
import { EventSearchService } from "../events-search/event-search.service";

@Component({
  selector: "check-in",
  templateUrl: "./check-in.component.html",
  styleUrls: ["./check-in.component.scss"]
})
export class CheckInComponent implements OnInit, OnDestroy {
  checkInNumbers: any;
  search = "";
  sortBy = "name";
  encoderList = [];
  chosenEncoder = '';
  autoWristband = false;
  visionlineResponse: any;
  currentBookingInfo: any;
  needsJoinerOrOverride: Boolean;
  joinerArray = [];
  // joinerOrOverrideSelection = '';
  joinerOrOverrideChoices = ['JOINER','OVERRIDE'];
  regStatus = { complete: true, incomplete: true };
  showCheckInWarning = false;
  showDoorAssignment = false;
  showJoinerOrOverrideModal = false;
  showNotification = false;
  notifMessage: string;
  notifObject: any;
  syncWarning = false;
  showSyncLoad = false;
  urlId: string;
  p: any;
  routeSub: Subscription;
  eventSub: Subscription;

  constructor(
    public moreInfoService: MoreInformationService,
    public route: ActivatedRoute,
    public router: Router,
    public shared: SharedService,
    public checkService: CheckInService,
    public eventSearch: EventSearchService,
    public loader: LoaderService,
    public apiService: ApiHttpService
  ) {}

  ngOnInit() {
    console.log(
       'this.eventSearch.camp: ', this.eventSearch.camp
    );    
    if(this.encoderList.length < 1 && this.shared.sharedEncoder == null)
      this.getEncoders();
    else if(this.shared.sharedEncoderList != null)
      this.encoderList = this.shared.sharedEncoderList;
    this.routeSub = this.route.params.subscribe(params => {
      this.urlId = params["id"];
      if (!this.shared.eventInfo.sfId) {
        console.log('c');
        this.loader.show();
        this.getEvent();
      } else {
        console.log('d');
        this.checkService.getGuests(this.urlId);
        this.checkService.getOppsInEvent(this.urlId);
      }
    });
    if(this.shared.sharedEncoder != null)
      this.chosenEncoder = this.shared.sharedEncoder;
  }

  searchChanged(value) {
    this.search = value;
  }

  infoOpening(id: string) {
    console.log('check-in component - infoOpening id: ', id);
    this.checkService.guests = this.moreInfoService.onlyOneAtATime(
      this.checkService.guests,
      id
    );
  }

  updateSharedEnc(sharedEnc: object){
    console.log('sharedEnc: ', sharedEnc);
    let sharedEncString = sharedEnc.toString();
    this.shared.sharedEncoder = sharedEncString;
    console.log('%cthis.shared.sharedEncoder: %s',"background: #93a8ff; color:black;", this.shared.sharedEncoder);
    
  }

  makeSingleKey(bookingInfo: object) {
    // this.checkService.guests = this.moreInfoService.onlyOneAtATime(
    //   this.checkService.guests,
    //   id
    // );
    console.log('bookingInfo: ', bookingInfo);
    console.log(this.chosenEncoder);
    if(this.encoderList.includes('OFFLINE MODE')){
      alert('You must be online in order to make a key or connect a wristband!');
      return;
    }
    if(this.chosenEncoder == ''){
      alert('You must choose an encoder first before making a key!');
      return;
    }
    if(this.chosenEncoder == 'OFFLINE MODE'){
      alert('You must be online in order to make a key or connect a wristband!');
      return;
    }
    this.makeKeyRequest(bookingInfo,this.chosenEncoder);
  }

  makeSelection(visibleList: any, selectType: boolean) {
    visibleList.forEach(element => (element.selected = selectType));
  }

  setDoorAssignmentValues() {
    this.showDoorAssignment = true;
    //shows modal, waits for answer from user
    this.checkService.moduleValue.subscribe(answer => {
      this.showDoorAssignment = false;
      if(answer){
        this.loader.show();
      }
    })
    this.loader.hide();
  }

  checkEncoderSelected(){
    if(this.encoderList.includes('OFFLINE MODE')){
      alert('You must be online before enabling auto wristband enrollment!');
      this.autoWristband = false;
      console.log('this.autoWristband: ', this.autoWristband);
      let elements = document.getElementsByName("autoWrist");
      for (let i = 0; i < elements.length; i++) {
        const chkbox = elements[i] as HTMLInputElement;
        chkbox.checked = false;
      }
    }
    if(this.chosenEncoder == ''){
      alert('You must select an encoder first before enabling auto wristband enrollment!');
      this.autoWristband = false;
      console.log('this.autoWristband: ', this.autoWristband);
      let elements = document.getElementsByName("autoWrist");
      for (let i = 0; i < elements.length; i++) {
        const chkbox = elements[i] as HTMLInputElement;
        chkbox.checked = false;
      }
    }
    if(this.chosenEncoder == 'OFFLINE MODE'){
      alert('You must be online before enabling auto wristband enrollment!');
      this.autoWristband = false;
      console.log('this.autoWristband: ', this.autoWristband);
      let elements = document.getElementsByName("autoWrist");
      for (let i = 0; i < elements.length; i++) {
        const chkbox = elements[i] as HTMLInputElement;
        chkbox.checked = false;
      }
    }
  }

  checkinMultiple() {
    // check if any guests that are being checked in have missing requirements
    let incompleteGuestInList = this.checkService.guests.find(
      guest => guest.selected && !guest.complete
    );
    if (incompleteGuestInList) {
      this.showCheckInWarning = true;
      // shows a modal and waits for an answer from the user
      this.checkService.moduleValue.subscribe(answer => {
        this.showCheckInWarning = false;
        if (answer) {
          this.loader.show();
          this.checkService.guests.forEach(guest => {
            if (guest.selected) {
              this.checkService.checkInOrOut(guest, true);
            }
          });
        }
      });
    } else {
      this.loader.show();
      this.checkService.guests.forEach(guest => {
        if (guest.selected) {
          this.checkService.checkInOrOut(guest, true);
        }
      });
    }
    this.loader.hide();
  }

  singleCheckIn(guest: any) {
    // check to see if this guest has incomplete requirements and show warning modal is so
    if (!guest.complete) {
      this.showCheckInWarning = true;
      this.checkService.moduleValue.subscribe(answer => {
        this.showCheckInWarning = false;
        if (answer) {
          this.checkService.checkInOrOut(guest, true);
        }
      });
    } else {
      this.checkService.checkInOrOut(guest, true);
    }
  }

  breakCrumbs(whereTo: string) {
    let hasPendingCheckIns =
      this.checkService.numCheckedIn > 0 && !this.checkService.pristineCheckIn;
    if (hasPendingCheckIns) {
      this.syncWarning = true;
      // show warning about nav-ing away without Syncing to Salesforce
      this.checkService.syncWarningValue.subscribe(answer => {
        this.syncWarning = false;
        if (answer === true) {
          this.routeAway(whereTo);
        } else if (answer === "sync") {
          this.setSync();
          this.routeAway(whereTo);
        }
      });
    } else {
      this.routeAway(whereTo);
    }
  }

  routeAway(whereTo: string) {
    if (whereTo === "events") {
      this.router.navigate(["./auth"]);
    } else if (whereTo === "accounts") {
      this.router.navigate(["./auth/accounts", this.shared.eventInfo.sfId]);
    }
  }

  setSync() {
    // Activated when Sync to Salesforce is clicked. The Event is marked with a flag and the service does the rest
    this.showSyncLoad = true;
    this.checkService.markForSync(this.shared.eventInfo.sfId).then(
      d => {
        this.showSyncLoad = false;
      },
      fail => {
        this.checkService.errorMsg = fail;
        this.checkService.showError = true;
      }
    );
  }

  makeKeyRequest(bookingInfo: object, selectedEncoder: string) {
    this.loader.show();
    this.currentBookingInfo = {};
    let reqBody = {bookingInfo: bookingInfo, encoder: selectedEncoder};
    this.currentBookingInfo = bookingInfo;
    // let resp = this.apiService.postRequest('http://127.0.0.1:3000/rfid/createRoomKey', reqBody).subscribe(
    // let resp = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/createRoomKey', reqBody).subscribe(
    let resp = this.apiService.postRequest('https://check-in-prod.herokuapp.com/rfid/createRoomKey', reqBody).subscribe(
      success => {
        console.log('success:' , success);
        this.loader.hide();
        this.visionlineResponse = {};
        if(success)
          this.visionlineResponse = success;
        console.log('visionlineResponse:', this.visionlineResponse);
        if(this.visionlineResponse.status && this.visionlineResponse.status == 400){
          alert('ERROR: ' + this.visionlineResponse.developerMessage);
          return;
        }
        this.needsJoinerOrOverride = false;
        this.joinerArray = [];
        // this.joinerOrOverrideSelection = '';
        if(this.visionlineResponse && this.visionlineResponse.message && this.visionlineResponse.message.includes('overlaps')){
          this.needsJoinerOrOverride = true;
          this.showJoinerOrOverrideModal = true;
          // console.log('%cthis.needsJoinerOrOverride: %s','background: #B2C6DE; color: black;',this.needsJoinerOrOverride);
          // console.log('%cthis.showJoinerOrOverrideModal: %s','background: #B2C6DE; color: black;',this.showJoinerOrOverrideModal);
          
        } else{
          this.notifMessage = 'Success! You have made a key for ' + this.currentBookingInfo.name;
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 2000);
        }
      },
      fail => {
        console.log('fail: ', fail);
        this.loader.hide();
        this.notifMessage = fail.message;
        this.showNotification = true;
        setTimeout(() => {
          this.showNotification = false;
        }, 2000);
      }
    );
    // console.log('Response', resp);
  }

  joinerOrOverride(choice: string){
    console.log('%cthis.chosenEncoder: %s','background: #FDF1CA; color:black;', this.chosenEncoder);
    console.log('%cchoice: %s','background: #C8DCB8; color:black;', choice);
    

    if(choice == 'CANCEL'){
      this.showJoinerOrOverrideModal = false;
      return;
    }

    if(this.needsJoinerOrOverride == true && choice == 'JOINER'){
      this.loader.show();
      let joinerString = this.visionlineResponse.developerMessage.split('(')[1];
      console.log('joinerString: ', joinerString);
      joinerString = joinerString.replace(')','');
      this.joinerArray.push(joinerString);
      console.log('joinerArray:', this.joinerArray);
      let reqBodyTwo = {bookingInfo: this.currentBookingInfo, encoder: this.chosenEncoder, option: choice, doorList: this.joinerArray};
      // let respTwo = this.apiService.postRequest('http://127.0.0.1:3000/rfid/createRoomKey', reqBodyTwo).subscribe(
      // let respTwo = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/createRoomKey', reqBodyTwo).subscribe(
      let respTwo = this.apiService.postRequest('https://check-in-prod.herokuapp.com/rfid/createRoomKey', reqBodyTwo).subscribe(
        success => {
          console.log('successTwo:' , success);
          this.notifObject = success;
          console.log('%cnO: %s','background: #93a8ff; color:black;', this.notifObject);
          this.loader.hide();
          this.showJoinerOrOverrideModal = false;
          if(this.notifObject.message.includes('Refused') || this.notifObject.status == 403 || this.notifObject.status == 504)
            this.notifMessage = 'Error: ' + this.notifObject.message;
          else
            this.notifMessage = this.notifObject.message +  '| You have made a key for ' + this.currentBookingInfo.name;
          this.showNotification = true;
          if(this.notifObject.status == 403){
            setTimeout(() => {
              this.showNotification = false;
            }, 7000);
            
          } else{
            setTimeout(() => {
              this.showNotification = false;
            }, 2000);
          }
        },
        fail => {
          console.log('failTwo: ', fail);
          this.loader.hide();
          this.notifMessage = fail.message;
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 2000);
          this.showJoinerOrOverrideModal = false;
          alert(fail);
        }
      );
    } else if(this.needsJoinerOrOverride == true && choice == 'OVERRIDE'){
      this.loader.show();
      let reqBodyThree = {bookingInfo: this.currentBookingInfo, encoder: this.chosenEncoder, option: choice};
      // let respThree = this.apiService.postRequest('http://127.0.0.1:3000/rfid/createRoomKey', reqBodyThree).subscribe(
      // let respThree = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/createRoomKey', reqBodyThree).subscribe(
      let respThree = this.apiService.postRequest('https://check-in-prod.herokuapp.com/rfid/createRoomKey', reqBodyThree).subscribe(
        success => {
          console.log('successThree:' , success);
          this.notifObject = success;
          console.log('%cnO: %s','background: #93a8ff; color:black;', this.notifObject);
          this.loader.hide();
          this.showJoinerOrOverrideModal = false;
          if(this.notifObject.message.includes('Refused') || this.notifObject.status == 403 || this.notifObject.status == 504)
            this.notifMessage = 'Error: ' + this.notifObject.message;
          else
            this.notifMessage = this.notifObject.message + '| You have made a key for ' + this.currentBookingInfo.name;
          this.showNotification = true;
          if(this.notifObject.status == 403){
            setTimeout(() => {
              this.showNotification = false;
            }, 7000);
            
          } else{
            setTimeout(() => {
              this.showNotification = false;
            }, 2000);
          }
        },
        fail => {
          console.log('failThree: ', fail);
          this.loader.hide();
          this.notifMessage = fail.message;
          this.showNotification = true;
          setTimeout(() => {
            this.showNotification = false;
          }, 2000);
          this.showJoinerOrOverrideModal = false;
          alert(fail);
        }
      );

    }
  }





  getEncoders() {
    // let resp = this.apiService.getRequest('http://127.0.0.1:3000/rfid/getEncoders').subscribe(
    // let resp = this.apiService.getRequest('https://check-in-staged.herokuapp.com/rfid/getEncoders').subscribe(
    let resp = this.apiService.getRequest('https://check-in-prod.herokuapp.com/rfid/getEncoders').subscribe(
      success => {
        console.log('getEncoders success: ', success);
        this.encoderList = [];
        success[0].forEach(e => {
          // console.log('e: ', e);
          this.encoderList.push(e.id);
          this.shared.sharedEncoderList.push(e.id);
        });
      },fail => {
        console.log('fail getEncoders: ', fail);
        this.encoderList = [];
        this.encoderList.push('OFFLINE MODE');
        this.shared.sharedEncoderList.push('OFFLINE MODE');
      }
    );
  }

  readWristbandRequest(selectedEncoder: string, invId: string) {
    this.loader.show();
    let reqBody = {encoder: selectedEncoder, inviteeId: invId};
    // let resp = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/createRoomKey', reqBody).subscribe(
    // let resp = this.apiService.postRequest('http://127.0.0.1:3000/rfid/readWristband', reqBody).subscribe(
    // let resp = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/readWristband', reqBody).subscribe(
    let resp = this.apiService.postRequest('https://check-in-prod.herokuapp.com/rfid/readWristband', reqBody).subscribe(
      success => {
        console.log('successWB: ',success);
        this.notifObject = success;
        if(Array.isArray(this.notifObject))
          this.notifObject = this.notifObject[0];
        console.log('%cnO: %s','background: #93a8ff; color:black;', this.notifObject);
        this.loader.hide();
        this.notifMessage = 'Success!';
        if(this.notifObject.message.includes('Refused') || this.notifObject.status == 403 || this.notifObject.status == 504)
          this.notifMessage = 'Error: ' + this.notifObject.message;
        else
          this.notifMessage = this.notifObject.message;
        this.showNotification = true;
        if(this.notifObject.status == 403 || this.notifObject.status == 504){
          setTimeout(() => {
            this.showNotification = false;
          }, 7000);
        } else{
          setTimeout(() => {
            this.showNotification = false;
          }, 2000);
        }
      },fail => {
        console.log('failWB: ', fail);
        this.loader.hide();
        this.notifMessage = 'Error: ' + fail.message;
        this.showNotification = true;
        setTimeout(() => {
          this.showNotification = false;
        }, 2000);
      }
    )
  };

  getEvent() {
    // This does all this because so staff can link this page and no have to go through the selection process
    // But this means that we have to query all the appropriate records to get to the guests
    if (this.checkService.returnObjType(this.urlId) === "oppId") {
      console.log('a');
      this.eventSub = this.shared
        .getSingleEventFromOpp(this.urlId)
        .subscribe(opp => {
          console.log('check-in.component - opp: ', opp);
          this.shared.getSingleEvent(opp[0].eventId).subscribe(success => {
            this.shared.eventInfo = success[0];
            this.checkService.getGuests(this.urlId);
            // this.checkService.getOppInEvent(this.urlId);
          });
        });
    } else {
      console.log('b');
      this.eventSub = this.shared.getSingleEvent(this.urlId).subscribe(
        success => {
          console.log('getSingleEvent success: ', success);
          this.shared.eventInfo = success[0];
          this.checkService.getGuests(this.urlId);
          this.checkService.getOppsInEvent(this.urlId);
        },
        fail => console.log(fail)
      );
    }
  }

  ngOnDestroy() {
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
    this.routeSub.unsubscribe();
  }
}
