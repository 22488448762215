import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "checkboxesFilter"
})
export class CheckboxesFilterPipe implements PipeTransform {
  transform(value: any, checkboxes: any[]): any {
    if (!value || !checkboxes) {
      return value;
    }

    return value.filter(item => {
      for (const checkbox of checkboxes) {
        let trueValue = checkbox.value
          ? checkbox.valueIfTrue
          : checkbox.valueIfFalse;

        if (item[checkbox.fieldName] === trueValue) {
          return true;
        }
      }
      return false;
    });
  }
}
