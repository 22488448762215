import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "checkedInFilter",
  pure: false
})
export class CheckedInPipe implements PipeTransform {
  transform(value: any, seeCheckedIn: string): any {
    if (!value) {
      return value;
    }

    if (seeCheckedIn === "hideCheckedIn") {
      return value.filter(it => !it.checkedIn || it.checkedIn === false);
    } else if (seeCheckedIn === "showCheckedIn") {
      return value.filter(it => it.checkedIn === true);
    }
  }
}
