import { AccountSearchComponent } from "./check-in-parent/account-search/account-search.component";
import { CheckInComponent } from "./check-in-parent/check-in/check-in.component";
import { CheckInParentComponent } from "./check-in-parent/check-in-parent.component";
import { EventsSearchComponent } from "./check-in-parent/events-search/events-search.component";
import { CreateFormComponent } from "./login/create-form/create-form.component";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RemoveComponent } from "./check-in-parent/check-in/remove/remove.component";
import { LoggedInGuard } from "./guards/logged-in.guard";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    children: [
      {
        path: "",
        component: LoginFormComponent
      },
      {
        path: "create",
        component: CreateFormComponent
      }
    ]
  },
  {
    path: "auth",
    component: CheckInParentComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: "",
        redirectTo: "events",
        pathMatch: "full"
      },
      {
        path: "events",
        component: EventsSearchComponent
      },
      {
        path: "accounts/:id",
        component: AccountSearchComponent
      },
      {
        path: "check-in/:id",
        children: [
          {
            path: "",
            component: CheckInComponent
          },
          {
            path: "check-out",
            component: RemoveComponent
          }
        ]
      }
    ]
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting {}
