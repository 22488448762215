import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from "firebase/app";

import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";

export interface PIN {
  required: string;
}

@Injectable()
export class AuthService {
  private usersCollection: AngularFirestoreCollection<any>;
  resetPassword = false;
  emailAddress: string;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.usersCollection = this.afs.collection<any>("users");
  }

  matchingPin(): Observable<any> {
    return this.afs.collection<PIN>("PIN").valueChanges();
  }

  login(form): Promise<string> {
    return this.afAuth.auth
      .signInWithEmailAndPassword(form.email.trim(), form.password.trim())
      .then(async success => {
        return { id: success.user.uid };
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }

  create(form): Promise<string> {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(form.email.trim(), form.password.trim())
      .then(async success => {
        return await this.addUserToDb(
          success.user.email,
          success.user.uid,
          form.program,
          form.location
        );
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }

  addUserToDb(email, uid, program, location): Promise<any> {
    return this.usersCollection
      .add({ program, location, uid, email })
      .then(ref => {
        return { id: ref.id };
      })
      .catch(error => {
        console.log(error);
      });
  }

  resetPasswordEmail(email): Promise<any> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
