import { LoaderService } from "../../loading/loader.service";
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class ApiHttpService {
  loading: boolean;
  data: any;
  error?: Error;


  constructor(
    private http: HttpClient,
    public loader: LoaderService
  ) {}

  getRequest(url: string, options?: any) { 
    console.log('url', url);
    // return this.http.get(url, options).subscribe(data => {
    //   return data;
    // }); 
    // return this.http.get(url, options);
    try {
      return this.http.get(url, options);
    } catch (error) {
      console.log('error from get request: ', error);
      return error;
    }
  } 
    
  postRequest(url: string, data: any, options?: any) { 
    // console.log('url: ', url);
    // console.log('data: ', data);

    // return this.http.post(url, data, options).subscribe(response => {
    //   console.log('response from node server: ', response);
    //   return response;
    // }); 
    return this.http.post(url, data, options);
  } 
   
}
