import { LoaderService } from "./../../loading/loader.service";
import { Colors } from "./../shared-services/colors";
import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import * as firebase from "firebase/app";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { SharedService } from "../shared-services/shared.service";
import { AccountsService } from "../account-search/account.service";
import { Subscription } from "rxjs";

@Injectable()
export class CheckInService {
  numCheckedIn: number;
  numRemaining: number;
  showError: boolean;
  errorMsg = "";
  guests: Array<any>;
  pristineCheckIn = true;
  queryId: string;
  private moduleSource = new Subject<any>();
  moduleValue = this.moduleSource.asObservable();
  private syncWarning = new Subject<any>();
  syncWarningValue = this.syncWarning.asObservable();
  checkedInBy: string;
  oppsWithBooking: Subscription;
  bookings: Array<any> = [];
  arrivedAt: Date;

  constructor(
    private afauth: AngularFireAuth,
    private afs: AngularFirestore,
    public route: ActivatedRoute,
    public colors: Colors,
    public loader: LoaderService,
    public shared: SharedService,
    public accountServ: AccountsService
  ) {}

  getGuests(id: string) {
    this.loader.show();
    this.showError = false;
    if (id !== this.queryId) {
      this.queryId = id;
      // Based on the Id passed in this will query with an Opp or Event Id
      this.queryGuests(this.returnObjType(id), id).subscribe(
        success => {
          console.log('success: ', success);
          this.guests = success;
          let availableForRFID = ['Hall of States','Chaparral','Trinity'];
          this.guests.forEach(g => {
            g.booking = [];
            this.bookings.forEach(b => {
              if(g.oppId == b.Opportunity__c){
                g.booking.push(b);
                if(availableForRFID.includes(b.Resource__r.Building__r.Name))
                  g.rfid = true;
              }
            })
          });
          this.updateCheckInNumbers(this.pristineCheckIn);
          this.loader.hide();
        },
        fail => {
          this.showError = true;
          this.errorMsg =
            "Couldn't find your list of guests. Try going back and selecting the event or account again.";
          console.log(fail);
          this.loader.hide();
        }
      );
    } else {
      this.loader.hide();
    }
  }

  getOppsInEvent(id: string) {
    console.log('start');
    console.log('this.queryId: ',this.queryId);
    console.log('id: ',id);    
    // this.loader.show();
    // this.showError = false;
    if (id !== this.queryId) {
      console.log('queryId different');
      this.queryId = id;
      // new function to get opps from event, hopefully getting the bookings too
      this.oppsWithBooking = this.accountServ.queryForOpps(id).subscribe(
        success => {
          console.log('getOppsInEvent?: ', success);          
          this.bookings = success;
          this.loader.hide();
        },
        fail => {
          this.setupError(fail);
          this.loader.hide();
        }
      );
    } else {
      console.log('queryId same');
      if(this.returnObjType(id) == 'eventId'){
        this.oppsWithBooking = this.accountServ.queryForOpps(id).subscribe(
          success => {
            console.log('getOppsInEvent?: ', success);
            // this.bookings = success;
            this.bookings = [];
            success.forEach(o => {
              if(o.bookings && o.bookings.length > 0){
                this.bookings = [...this.bookings, ...o.bookings];
              }
            });
            console.log('this.bookings: ', this.bookings);
            let availableForRFID = ['Hall of States','Chaparral','Trinity'];
            this.guests.forEach(g => {
              g.booking = [];
              this.bookings.forEach(b => {
                if(g.oppId == b.Opportunity__c){
                  g.booking.push(b);
                  if(availableForRFID.includes(b.Resource__r.Building__r.Name))
                    g.rfid = true;
                }
              })
            });
            this.loader.hide();
            console.log('this.guests: ', this.guests);
          },
          fail => {
            this.setupError(fail);
            this.loader.hide();
          }
        );
      } else if(this.returnObjType(id) == 'oppId'){
        this.oppsWithBooking = this.accountServ.queryForOpp(id).subscribe(
          success => {
            console.log('getOpp?: ', success);
            // this.bookings = success;
            this.bookings = [];
            success.forEach(o => {
              if(o.bookings && o.bookings.length > 0){
                this.bookings = [...this.bookings, ...o.bookings];
              }
            });
            console.log('this.bookings: ', this.bookings);
            let availableForRFID = ['Hall of States','Chaparral','Trinity'];
            this.guests.forEach(g => {
              g.booking = [];
              this.bookings.forEach(b => {
                if(g.oppId == b.Opportunity__c){
                  g.booking.push(b);
                  if(availableForRFID.includes(b.Resource__r.Building__r.Name))
                    g.rfid = true;
                }
              })
            });
            this.loader.hide();
            console.log('this.guests: ', this.guests);
          },
          fail => {
            this.setupError(fail);
            this.loader.hide();
          }
        );
      }
    }
  }

  // getOppInEvent(id: string) {
  //   console.log('start');
  //   console.log('this.queryId: ',this.queryId);
  //   console.log('id: ',id);    
  //   // this.loader.show();
  //   // this.showError = false;
  //   if (id !== this.queryId) {
  //     console.log('queryId different');
  //     this.queryId = id;
  //     // new function to get opps from event, hopefully getting the bookings too
  //     this.oppsWithBooking = this.accountServ.queryForOpp(id).subscribe(
  //       success => {
  //         console.log('getOppsInEvent?: ', success);          
  //         this.bookings = success;
  //         this.loader.hide();
  //       },
  //       fail => {
  //         this.setupError(fail);
  //         this.loader.hide();
  //       }
  //     );
  //   } else {
  //     console.log('queryId same');
  //     if(this.returnObjType(id) == 'eventId'){
  //       this.oppsWithBooking = this.accountServ.queryForOpp(id).subscribe(
  //         success => {
  //           console.log('getOppsInEvent?: ', success);
  //           // this.bookings = success;
  //           this.bookings = [];
  //           success.forEach(o => {
  //             if(o.bookings && o.bookings.length > 0){
  //               this.bookings = [...this.bookings, ...o.bookings];
  //             }
  //           });
  //           console.log('this.bookings: ', this.bookings);
  //           let availableForRFID = ['Hall of States','Chaparral','Trinity'];
  //           this.guests.forEach(g => {
  //             g.booking = [];
  //             this.bookings.forEach(b => {
  //               if(g.oppId == b.Opportunity__c){
  //                 g.booking.push(b);
  //                 if(availableForRFID.includes(b.Resource__r.Building__r.Name))
  //                   g.rfid = true;
  //               }
  //             })
  //           });
  //           this.loader.hide();
  //           console.log('this.guests: ', this.guests);
  //         },
  //         fail => {
  //           this.setupError(fail);
  //           this.loader.hide();
  //         }
  //       );
  //     } else if(this.returnObjType(id) == 'oppId'){
  //       this.oppsWithBooking = this.accountServ.queryForOpp(id).subscribe(
  //         success => {
  //           console.log('getOpp?: ', success);
  //           // this.bookings = success;
  //           this.bookings = [];
  //           success.forEach(o => {
  //             if(o.bookings && o.bookings.length > 0){
  //               this.bookings = [...this.bookings, ...o.bookings];
  //             }
  //           });
  //           console.log('this.bookings: ', this.bookings);
  //           let availableForRFID = ['Hall of States','Chaparral','Trinity'];
  //           this.guests.forEach(g => {
  //             g.booking = [];
  //             this.bookings.forEach(b => {
  //               if(g.oppId == b.Opportunity__c){
  //                 g.booking.push(b);
  //                 if(availableForRFID.includes(b.Resource__r.Building__r.Name))
  //                   g.rfid = true;
  //               }
  //             })
  //           });
  //           this.loader.hide();
  //           console.log('this.guests: ', this.guests);
  //         },
  //         fail => {
  //           this.setupError(fail);
  //           this.loader.hide();
  //         }
  //       );
  //     }
  //   }
  // }

  queryGuests(idType: string, id: string) {
    // This may or may not query from the cache from the cache based on the connectivity
    return this.afs
      .collection<any>("guests", ref =>
        ref.where(idType, "==", id).orderBy("name", "asc")
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            console.log(
              "check in guest from cache: ",
              a.payload.doc.metadata.fromCache
            );
            return a.payload.doc.data();
          })
        )
      );
  }

  updateCheckInNumbers(stayPristine?: boolean) {
    if (stayPristine) {
      this.pristineCheckIn = true;
    } else {
      this.pristineCheckIn = false;
    }
    this.numCheckedIn = 0;
    this.numRemaining = 0;
    for (let i = 0; i < this.guests.length; i++) {
      if (this.guests[i].checkedIn) {
        this.numCheckedIn++;
      } else {
        this.numRemaining++;
      }
    }
  }

  moduleValueReceived(value) {
    this.moduleSource.next(value);
  }

  syncModelResp(value) {
    this.syncWarning.next(value);
  }

  markForSync(eventId: string): Promise<any> {
    // Updates the DB with the flag that triggers the Salesforce update
    this.showError = false;
    this.pristineCheckIn = true;
    return this.afs
      .collection("events")
      .doc(eventId)
      .update({ needToSync: true });
  }

  checkInOrOut(guest: any, checkType: boolean) {
    // take one guest at a time for a check in or check out
    this.showError = false;
    guest.selected = false;
    this.updateCheckInNumbers();
    this.checkedInBy = this.setCheckedInBy(checkType);
    this.arrivedAt = this.setArrivedAt(checkType);
    this.afs
      .collection("guests")
      .doc(guest.sfId)
      .update({
        checkedIn: checkType,
        checkedInBy: this.checkedInBy,
        arrivedAt: this.arrivedAt
      });
  }

  setCheckedInBy(checkType: boolean): any {
    if (checkType) {
      return this.afauth.auth.currentUser.email;
    } else {
      return null;
    }
  }

  setArrivedAt(checkType: boolean): any {
    if (checkType) {
      return new Date();
    } else {
      return null;
    }
  }

  returnObjType(id): string {
    if (id.startsWith("006")) {
      return "oppId";
    } else if (id.startsWith("a0L")) {
      return "eventId";
    }
  }

  setupError(msg: any) {
    this.errorMsg = msg;
    this.showError = true;
  }
}
