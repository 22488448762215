import { LoaderService } from "./../../loading/loader.service";
import { MoreInformationService } from "./../more-information/more-information.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { SharedService } from "../shared-services/shared.service";
import { ActivatedRoute } from "@angular/router";
import { PaginationInstance } from "ngx-pagination";
import { Subscription } from "rxjs";
import { AccountsService } from "./account.service";

@Component({
  selector: "account-search",
  templateUrl: "./account-search.component.html",
  styleUrls: ["./account-search.component.scss"]
})
export class AccountSearchComponent implements OnInit, OnDestroy {
  errorMsg: string;
  showError = false;
  openInfoId: string;
  otherInfoOpen = false;
  search: string;
  sortBy = "account";
  regStatus = {
    complete: true,
    incomplete: true
  };
  p: any;
  accounts: Array<any> = [];
  queryId: string;
  accountsSub: Subscription;
  routeSub: Subscription;

  constructor(
    public moreInfoService: MoreInformationService,
    private activatedRoute: ActivatedRoute,
    public loader: LoaderService,
    public shared: SharedService,
    public accountServ: AccountsService
  ) {}

  ngOnInit() {
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      this.setupData(params["id"]);
    });
  }

  getSubOpps(id: string) {
    if (id !== this.queryId) {
      this.loader.show();
      this.queryId = id;
      this.accountsSub = this.accountServ.queryForOpps(id).subscribe(
        success => {
          console.log('subOpps?: ', success);          
          this.accounts = success;
          this.loader.hide();
        },
        fail => {
          this.setupError(fail);
          this.loader.hide();
        }
      );
    }
  }

  setupData(eventId: string) {
    if (!this.shared.eventInfo.sfId) {
      this.shared
        .getSingleEvent(eventId)
        .subscribe(
          success => (this.shared.eventInfo = success[0]),
          fail => this.setupError(fail),
          () => this.getSubOpps(eventId)
        );
    } else {
      this.getSubOpps(eventId);
    }
  }

  // required to be here for the More Information section
  infoOpening(id: string) {
    this.accounts = this.moreInfoService.onlyOneAtATime(this.accounts, id);
  }

  searchChanged(value) {
    this.search = value;
  }

  setupError(msg: any) {
    this.errorMsg = msg;
    this.showError = true;
  }

  // avoid memory leaks by destroying subscriptions
  ngOnDestroy() {
    this.accountsSub.unsubscribe();
    this.routeSub.unsubscribe();
  }
}
