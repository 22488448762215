import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";

@Component({
  selector: "more-information",
  templateUrl: "./more-information.component.html",
  styleUrls: ["./more-information.component.scss"]
})
export class MoreInformationComponent implements OnInit {
  @ViewChild("relativeHeight", { static: true }) relativeHeight: HTMLElement;
  @Input() info: any;
  @Input() eventReqs: any;
  @Input() fromAccount: boolean;
  @Input() completedReqs: any;
  balance: number;

  requirementList: Array<any> = new Array();

  constructor() {}

  // all the params are passed from the parent
  ngOnInit() {
    this.balance = this.info.balance;
    for (let req of this.eventReqs) {
      this.requirementList.push({
        name: req,
        complete: this.completedReqs.includes(req)
      });
    }
    for (let adult of this.info.authAdults || []) {
      adult.birthday = new Date(adult.birthday);
    }
  }
}
