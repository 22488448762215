import {
  Component,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  OnInit
} from "@angular/core";

import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { fromEvent } from "rxjs";
import { map } from "rxjs/internal/operators/map";

// RXJS leveraged heavily here to help with the debounced input field
@Component({
  selector: "debounced-input",
  templateUrl: "./debounced-input.component.html",
  styleUrls: ["./debounced-input.component.scss"]
})
export class DebouncedInputComponent implements OnInit {
  @Input() placeholder: String;
  @Input() delay: number;
  @Output() value = new EventEmitter();

  public inputValue: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const eventStream = fromEvent(this.elementRef.nativeElement, "keyup").pipe(
      debounceTime(this.delay),
      distinctUntilChanged()
    );
    eventStream.subscribe(input => this.value.emit(input));
  }
}
