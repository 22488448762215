export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCT9MGBltzpvqNIfC2jZmRQxOUebGI1vuI",
    authDomain: "checkin-dev-d7d7f.firebaseapp.com",
    databaseURL: "https://checkin-dev-d7d7f.firebaseio.com",
    projectId: "checkin-dev-d7d7f",
    storageBucket: "checkin-dev-d7d7f.appspot.com",
    messagingSenderId: "726724231786"
  }
};
