import { Observable, Subscription, forkJoin } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { SharedService } from "./shared-services/shared.service";
import { map } from "rxjs/operators";
import { LoaderService } from "../loading/loader.service";

@Component({
  selector: "check-in-parent",
  templateUrl: "./check-in-parent.component.html",
  styleUrls: ["./check-in-parent.component.scss"]
})
export class CheckInParentComponent implements OnInit {
  eventSearchState: any;
  offline = false;

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    public service: SharedService,
    public loader: LoaderService
  ) {}

  ngOnInit() {}

  logout() {
    this.afAuth.auth.signOut();
    this.router.navigate(["./login"]);
  }

  goToHome() {
    if (this.afAuth.auth.currentUser) {
      this.router.navigate(["./auth"]);
    } else {
      this.router.navigate(["./login"]);
    }
  }
}
