import { CheckInService } from "./../check-in.service";
import { MoreInformationService } from "./../../more-information/more-information.service";
import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { CheckInComponent } from "../check-in.component";
import { SharedService } from "../../shared-services/shared.service";

@Component({
  selector: "remove",
  templateUrl: "./remove.component.html",
  styleUrls: ["./remove.component.scss"]
})

// The checkout or un-checkin page
export class RemoveComponent implements OnInit {
  search = "";
  sortBy: any;
  showError = false;
  errorMsg = "";
  regStatus = { complete: true, incomplete: true };
  p: any;

  constructor(
    public moreInfoService: MoreInformationService,
    public location: Location,
    public checkService: CheckInService,
    public shared: SharedService,
    public checkComponent: CheckInComponent
  ) {}

  ngOnInit() {
    if(this.checkComponent.encoderList.length < 1 && this.shared.sharedEncoder == null)
      this.checkComponent.getEncoders();
    else if(this.shared.sharedEncoderList != null)
      this.checkComponent.encoderList = this.shared.sharedEncoderList;
    if(this.shared.sharedEncoder != null)
      this.checkComponent.chosenEncoder = this.shared.sharedEncoder;
  }

  infoOpening(id: string) {
    this.checkService.guests = this.moreInfoService.onlyOneAtATime(
      this.checkService.guests,
      id
    );
  }

  searchChanged(value) {
    this.search = value;
  }

  makeSelection(visibleList: any, selectType: boolean) {
    visibleList.forEach(element => (element.selected = selectType));
  }

  checkoutMultiple() {
    this.checkService.guests.forEach(guest => {
      if (guest.selected) {
        this.checkService.checkInOrOut(guest, false);
      }
    });
  }

  singleCheckOut(guest: any) {
    this.checkService.checkInOrOut(guest, false);
  }

  makeSingleKey(bookingInfo: object) {
    // this.checkService.guests = this.moreInfoService.onlyOneAtATime(
    //   this.checkService.guests,
    //   id
    // );
    console.log('bookingInfo: ', bookingInfo);
    console.log(this.checkComponent.chosenEncoder);
    if(this.checkComponent.chosenEncoder == ''){
      alert('You must choose an encoder first before making a key!');
      return;
    }
    this.checkComponent.makeKeyRequest(bookingInfo,this.checkComponent.chosenEncoder);
  }
}
