import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "../../shared-services/shared.service";

@Component({
  selector: "event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"]
})

// This is the presentational component that displays the Events
export class EventComponent implements OnInit {
  @Input() color: string;
  @Input() event: any;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public shared: SharedService
  ) {}

  ngOnInit() {}

  goToCheckIn() {
    this.shared.eventInfo = this.event;
    this.shared.accountInfo = {};
    this.router.navigate(["./auth/check-in", this.event.sfId]);
  }

  goToAccounts() {
    this.shared.eventInfo = this.event;
    this.router.navigate(["./auth/accounts", this.event.sfId]);
  }
}
