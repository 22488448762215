import { AuthService } from "./../auth.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  showError = false;
  errorMsg: string;
  showSuccess = false;
  processing = false;
  email: string;

  constructor(public auth: AuthService) {}

  ngOnInit() {
    this.email = this.auth.emailAddress;
  }

  sendResetEmail() {
    this.processing = true;
    this.auth
      .resetPasswordEmail(this.email.trim())
      .then(
        success => ((this.showSuccess = true), (this.processing = true)),
        fail => ((this.showError = true), (this.errorMsg = fail))
      );
  }

  toggleModal() {
    this.auth.resetPassword = !this.auth.resetPassword;
    this.showSuccess = false;
    this.processing = false;
  }
}
