import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cutText"
})
export class CutTextPipe implements PipeTransform {
  transform(value: any, length: number): any {
    if (!value) {
      return value;
    }

    return `${value.slice(0, length)}...`;
  }
}
