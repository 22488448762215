import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchFilter"
})
export class SearchPipe implements PipeTransform {
  transform(value: any[], search?: any, keys?: string[]): any {
    if (!value || !search) {
      return value;
    }

    return value.filter(item => {
      for (let key of keys) {
        if (
          item[key].toUpperCase().includes(search.target.value.toUpperCase())
        ) {
          return true;
        }
      }
      return false;
    });
  }
}
