import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "regStatus"
})
export class RegStatusPipe implements PipeTransform {
  transform(value: any, complete: boolean, incomplete: boolean): any {
    if (!value) {
      return value;
    }

    if (complete && incomplete) {
      return value;
    } else if (!complete && !incomplete) {
      return [];
    } else if (!complete) {
      return value.filter(it => it.complete !== true);
    } else if (!incomplete) {
      return value.filter(it => it.complete === true);
    }
  }
}
