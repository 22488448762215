import { SharedService } from "./../../check-in-parent/shared-services/shared.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthService } from "./../auth.service";
import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";

@Component({
  selector: "create-form",
  templateUrl: "./create-form.component.html",
  styleUrls: ["./create-form.component.scss"],
  providers: [AuthService, AngularFireAuth]
})
export class CreateFormComponent implements OnInit {
  showError = false;
  errorMsg = "";
  camps = new Set();
  programs = new Array();
  camp: string;

  constructor(
    private auth: AuthService,
    public router: Router,
    public shared: SharedService
  ) {}

  ngOnInit() {
    this.getCampPrograms();
  }

  createAccount(form) {
    this.showError = false;
    this.auth.matchingPin().subscribe(async data => {
      if (data[0].required === form.value.pin) {
        this.handleLogin(await this.auth.create(form.value));
      } else {
        this.handleLogin({
          message:
            "The PIN you entered is not correct. Please enter a new one and try again"
        });
      }
    });
  }

  handleLogin(loginResponse) {
    if (loginResponse.id) {
      this.router.navigate(["./auth/events"]);
    }

    this.errorMsg = loginResponse.message;
    this.showError = true;
  }

  getCampPrograms() {
    this.shared.campPrograms().subscribe(data => {
      for (let d of data) {
        this.camps.add(d.camp);
        this.programs.push({ name: d.program, camp: d.camp });
      }
      this.programs.sort(this.shared.compare);
    });
  }

  campChange(newValue) {
    this.camp = newValue;
  }
}
