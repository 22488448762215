import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "programFilter"
})
export class ProgramPipe implements PipeTransform {
  transform(value: any[], camp: string): any {
    if (!value) {
      return value;
    }

    return value.filter(it => it.camp === camp).sort();
  }
}
