import { Injectable } from "@angular/core";

@Injectable()
export class MoreInformationService {
  constructor() {}

  // There's room if you want to make it available to open more than one "more info" panels
  onlyOneAtATime(data: any, id: string) {
    for (const elm of data) {
      if (elm.showInfo && id === elm.sfId) {
        elm.showInfo = false;
      } else if (id === elm.sfId) {
        elm.showInfo = true;
      } else {
        elm.showInfo = false;
      }
    }
    return data;
  }
}
