import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByFilter"
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], key: string): any {
    if (!value || !key) {
      return value;
    }
    if (key === 'lastname'){
      return value.sort((a, b) => {
        if (a['name'].split(' ')[1] < b['name'].split(' ')[1]) return -1;
        if (a['name'].split(' ')[1] > b['name'].split(' ')[1]) return 1;
        return 0;
      });
    }


    return value.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }
}
