// Most programs have different colors. Hopefully this helps differentiate the events and programs for staff
export class Colors {
  programColors = {
    AFTC: "blue",
    DayCamp: "blue",
    SportsCamp: "blue",
    Conferences: "orange",
    ZipTour: "orange",
    GroupCamp: "teal",
    Discipleship: "green",
    TrailCrew: "green",
    Wilderness: "green",
    WildernessTrek: "green",
    ProfessionalSemester: "green",
    GapYear: "green",
    OutdoorEd: "green",
    FamilyCamp: "red",
    FriendsFamily: "purple",
    Public: "purple",
    Retreats: "purple",
    Schools: "purple",
    ServiceTeam: "yellow",
    IndividualCamp: "yellow"
  };
}
