import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phoneNumber"
})
export class PhoneNumberPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return value;
    }

    value = value
      .replace("(", "")
      .replace(/ /g, "")
      .replace(")", "")
      .replace(/\./g, "")
      .replace(/-/g, "");

    return `${value.substring(0, 3)}-${value.substring(3, 6)}-${value.substring(
      6,
      10
    )}`;
  }
}
