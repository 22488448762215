import { LoaderService } from "./../../loading/loader.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import * as firebase from "firebase/app";

@Injectable()
export class AccountsService {
  queryId: String;
  accounts: Array<any>;

  constructor(private afs: AngularFirestore, public loader: LoaderService) {}

  queryForOpps(eventId: String) {
    return this.afs
      .collection<any>("opportunities", ref =>
        ref.where("eventId", "==", eventId).orderBy("account", "asc")
      )
      .valueChanges();
  }

  queryForOpp(oppId: String) {
    return this.afs
      .collection<any>("opportunities", ref =>
        ref.where("sfId", "==", oppId)
      )
      .valueChanges();
  }
}
