import { ApiHttpService } from './../check-in/api-http-service.service';
import { Colors } from "./../shared-services/colors";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { PaginationInstance } from "ngx-pagination";
import { SharedService } from "../shared-services/shared.service";
import { LoaderService } from "./../../loading/loader.service";
import { EventSearchService } from "./event-search.service";

@Component({
  selector: "events-search",
  templateUrl: "./events-search.component.html",
  styleUrls: ["./events-search.component.scss"]
})
export class EventsSearchComponent implements OnInit, OnDestroy {
  camps = new Set<string>();
  programs = Array<any>();
  visiblePrograms = new Set<any>();
  arrivalDate = {
    date: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    }
  };
  arrivalOnly = true;
  camp: string;
  program: string;
  errorMsg: string;
  showError = false;
  eventList: Array<any>;
  color: string;
  p: any;
  search: string;

  constructor(
    public shared: SharedService,
    public loader: LoaderService,
    public colors: Colors,
    public eventServ: EventSearchService,
    public apiService: ApiHttpService
  ) {}

  ngOnInit() {
    this.getCampPrograms();
    this.getUserDefaults();
  }

  ngOnDestroy() {
    // When the component is routed away from these subscriptions need to be closed to reduce memory usage (memory leak)
    this.eventServ.program = this.program;
    this.eventServ.camp = this.camp;
    this.eventServ.arrivalDate = this.arrivalDate;
    this.eventServ.arrivalOnly = this.arrivalOnly;
  }

  makeRequest() {
    let reqBody = {action: 'test-action-two', body: 'test-body-two'};
    // let resp = this.apiService.postRequest('https://check-in-staged.herokuapp.com/rfid/createRoomKey', reqBody).subscribe(
    let resp = this.apiService.postRequest('https://check-in-prod.herokuapp.com/rfid/createRoomKey', reqBody).subscribe(
      success => {
        console.log('success:' , success);
        
      }
    );
    // console.log('Response', resp);
  }

  searchChanged(value) {
    this.search = value;
  }

  getUserDefaults() {
    if (!this.eventServ.program || !this.eventServ.camp) {
      this.loader.show();
      this.shared.getUser().subscribe(
        success => {
          if (!success) {
            this.setupError(
              "There was a problem connecting to the database. Try refreshing the page."
            );
          }
          this.camp = success[0].location;
          this.program = success[0].program;
          this.getEvents(this.camp, this.program);
        },
        fail => {
          this.setupError(fail);
          this.loader.hide();
        }
      );
    }
  }

  getCampPrograms() {
    this.shared.campPrograms().subscribe(data => {
      for (let d of data) {
        this.camps.add(d.camp);
        this.programs.push({ name: d.program, camp: d.camp });
      }
      this.programs.sort(this.shared.compare);

      if (this.eventServ.program || this.eventServ.camp) {
        this.camp = this.eventServ.camp;
        this.program = this.eventServ.program;
        this.arrivalDate = this.eventServ.arrivalDate;
        this.arrivalOnly = this.eventServ.arrivalOnly;
        this.getEvents(this.camp, this.program);
      }
    });
  }

  getEvents(camp: string, program: string) {
    this.shared.getEvents(camp, program).subscribe(
      success => {
        console.log('events-search - getEvents success: ', success);
        this.eventList = success;
        this.color = this.colors.programColors[this.program.replace(" ", "")];
        this.loader.hide();
      },
      fail => (this.setupError(fail), this.loader.hide())
    );
  }

  getNewEvents(newValue) {
    this.getEvents(this.camp, this.program);
  }

  changeProgram() {
    this.program = "";
    this.eventList = [];
  }

  setupError(msg) {
    this.errorMsg = msg;
    this.showError = true;
  }
}
