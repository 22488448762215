import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { Colors } from "./../../shared-services/colors";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from "../../shared-services/shared.service";

@Component({
  selector: "account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"]
})

// Presentational component for an Opp(Account)
export class AccountComponent implements OnInit {
  showInfo = false;
  color: string;
  @Input() info: any;
  @Output() infoOpening = new EventEmitter<string>();

  constructor(
    public shared: SharedService,
    public colors: Colors,
    public router: Router
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  showMoreInfo() {
    this.infoOpening.emit(this.info.sfId);
  }

  goToCheckIn() {
    this.shared.accountInfo = this.info;
    this.router.navigate(["./auth/check-in", this.info.sfId]);
  }

  setupPage() {
    this.color = this.colors.programColors[
      this.shared.eventInfo.program.replace(" ", "")
    ];
  }
}
