import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "arrivalDateFilter"
})
export class ArrivalDatePipe implements PipeTransform {
  transform(value: any, arrival: any, only: boolean): any {
    if (!value) {
      return value;
    }

    if (!arrival) {
      return [];
    }

//Uncomment below
    // if (arrival.date.month.toString().length < 2) {
    //   arrival.date.month = "0" + arrival.date.month;
    // }

    // if (arrival.date.day.toString().length < 2) {
    //   arrival.date.day = "0" + arrival.date.day;
    // }
//Uncomment above

    let selectedDate = new Date(
      `${arrival.date.year}-${arrival.date.month.toString().length < 2 ? '0' + arrival.date.month : arrival.date.month}-${arrival.date.day.toString().length < 2 ? '0' + arrival.date.day : arrival.date.day}`
    ).getTime();
    console.log('%cselectedDate: %s','background: #93a8ff; color:black', selectedDate);
    

    if (!only) {
      return value.filter(it => new Date(it.arrival).getTime() >= selectedDate);
    }

    return value.filter(it => new Date(it.arrival).getTime() === selectedDate);
  }
}
