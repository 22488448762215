import { Injectable } from "@angular/core";

@Injectable()
export class EventSearchService {
  program: string;
  camp: string;
  arrivalDate: { date: { year: number; month: number; day: number } };
  arrivalOnly: boolean;

  constructor() {}
}
