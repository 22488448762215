import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "checkboxes",
  templateUrl: "./checkboxes.component.html",
  styleUrls: ["./checkboxes.component.scss"]
})
export class CheckboxesComponent implements OnInit {
  @Input() public header: string;
  @Input() public checkboxes: any;
  @Output() public checkboxesChanged = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    this.updateCheckboxes();
  }

  updateCheckboxes() {
    this.checkboxesChanged.emit(this.checkboxes);
  }
}
