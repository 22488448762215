import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "./../auth.service";
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: "login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
  providers: [AuthService, AngularFireAuth]
})
export class LoginFormComponent implements OnInit {
  errorMsg: string;
  showError = false;
  resetPassword = false;
  constructor(public auth: AuthService, private router: Router) {}

  ngOnInit() {}

  async login(form) {
    this.showError = false;
    this.handleLogin(await this.auth.login(form.value));
  }

  handleLogin(loginResponse) {
    if (loginResponse.id) {
      this.router.navigate(["./auth/events"]);
    }

    this.errorMsg = loginResponse.message;
    this.showError = true;
  }

  checkValidity(form) {
    return form.value.email !== "" && form.value.password !== "";
  }

  toggleResetPasswordModal(form) {
    this.auth.emailAddress = form.value.email.trim();
    this.auth.resetPassword = !this.auth.resetPassword;
  }
}
